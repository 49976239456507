// Entry point for the build script in your package.json

// Responsive embeds
import responsiveEmbeds from "./lib/responsiveEmbeds";
responsiveEmbeds();

type ReadyHandler = () => void;

const readyHandlers: ReadyHandler[] = [];

// Ready handler
function handleState() {
  if (["interactive", "complete"].indexOf(document.readyState) > -1) {
    while (readyHandlers.length > 0) {
      readyHandlers.shift()();
    }
  }
}

function ready(handler: ReadyHandler) {
  readyHandlers.push(handler);
  handleState();
}
document.onreadystatechange = handleState;

ready(function () {
  const mobileToggle = document.querySelector(".mobile-toggle a");

  // Toggle mobile
  mobileToggle.addEventListener("click", function (evt) {
    evt.preventDefault();
    document.body.classList.toggle("mobile-nav");
  });
});
